import { Add, AppRegistration, FilterAltOff, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TablePagination,
  TextField,
  Typography
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { ChipStatusSelect } from 'components/chips'
import { useAuth, useLayout } from 'context'
import * as controllers from 'controllers'
import { OpportunityDto, PersonDto, StatusCount } from 'dtos'
import { useQuery } from 'hooks'
import { OpportunitiesParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { getStatusCount, useDebounce } from 'utils'
import OpportunityHeroCard from './OpportunityHeroCard'
import { useNavigate } from 'react-router-dom'
import OpportunityActivityAddDialog from './id/OpportunityActivityAddDialog'
import { enqueueSnackbar } from 'notistack'
import OpportunityAddEditDialog from './id/OpportunityAddEditDialog'

const sessionStorageKey = 'opportunities:parameters'

export default function Opportunities() {
  const {
    user,
    permissions: { SALESPERSON }
  } = useAuth()
  const navigate = useNavigate()
  const query = useQuery()

  const defaultParameters: OpportunitiesParameters = {
    page: 0,
    pageSize: 10,
    search: query.get('search') ?? '',
    statuses: ['isOpportunity', 'isSendProposal', 'isGetApproval', 'isGetPayment', 'isPastDue'],
    salespersonId: user!.id,
    order: 'asc',
    orderBy: 'nextActivityDate'
  }

  const { setIsLayoutLoading, setTitle } = useLayout()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [opportunities, setOpportunities] = useState<OpportunityDto[]>([])
  const [opportunity, setOpportunity] = useState<OpportunityDto>(new OpportunityDto())
  const [opportunityActivityAddDialogOpen, setOpportunityActivityAddDialogOpen] = useState<boolean>(false)
  const [isCreatingOpportunityActivity, setIsCreatingOpportunityActivity] = useState<boolean>(false)
  const [parameters, setParameters] = useState<OpportunitiesParameters>(
    sessionStorage.getItem(sessionStorageKey) ? JSON.parse(sessionStorage.getItem(sessionStorageKey)!) : defaultParameters
  )
  const [salespeople, setSalespeople] = useState<PersonDto[]>([])
  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([])
  const [isCreatingOpportunity, setIsCreatingOpportunity] = useState<boolean>(false)
  const [opportunityAddEditDialogOpen, setOpportunityAddEditDialogOpen] = useState<boolean>(false)

  const onFilter = useDebounce((parameters: OpportunitiesParameters) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(parameters))

    setIsLayoutLoading(true)
    controllers
      .getOpportunities(parameters)
      .then(res => {
        setOpportunities(res.value)
        setTotalCount(res.totalCount!)
        setStatusCounts(res.statusCounts ?? [])
      })
      .catch(() => {})
      .finally(() => {
        setIsLayoutLoading(false)
      })
  }, 300)

  useEffect(() => {
    setTitle('Opportunities')

    controllers
      .getPeople({
        page: 0,
        pageSize: 1000,
        permission: 'SALESPERSON',
        statuses: ['isUser']
      })
      .then(res => {
        setSalespeople(res.value)
      })
      .catch(() => {})
      .finally(() => {})
  }, [])

  useEffect(() => {
    if (onFilter) {
      onFilter(parameters)
    }
  }, [onFilter, parameters])

  return (
    <>
      <OpportunityAddEditDialog
        initialValues={opportunity}
        isLoading={isCreatingOpportunity}
        onClose={() => {
          setOpportunityAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsCreatingOpportunity(true)
          controllers
            .createOpportunity(values)
            .then(() => {
              onFilter(parameters)
              setOpportunityAddEditDialogOpen(false)
              enqueueSnackbar('Opportunity Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsCreatingOpportunity(false)
            })
        }}
        open={opportunityAddEditDialogOpen}
      />

      <OpportunityActivityAddDialog
        isLoading={isCreatingOpportunityActivity}
        onClose={() => {
          setOpportunityActivityAddDialogOpen(false)
        }}
        onSave={values => {
          setIsCreatingOpportunityActivity(true)
          controllers
            .addActivity({ ...values, opportunity })
            .then(() => {
              // We aren't using response because re-fetch list.
              onFilter(parameters) // Re=fetch list
              setOpportunityActivityAddDialogOpen(false)
              enqueueSnackbar('Activity Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsCreatingOpportunityActivity(false)
            })
        }}
        open={opportunityActivityAddDialogOpen}
      />

      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography fontWeight='bold' variant='h5'>
                Opportunities ({totalCount})
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                <Grid item xs={12} sm={true} container spacing={1}>
                  <Grid item xs={12} sm='auto'>
                    <TextField
                      data-search
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <SearchOutlined fontSize='small' />{' '}
                          </InputAdornment>
                        )
                      }}
                      label='Search'
                      onChange={e => {
                        setParameters({ ...parameters, search: e.target.value ?? undefined })
                      }}
                      size='small'
                      sx={{ minWidth: '200px', maxWidth: '200px' }}
                      type='search'
                      value={parameters.search}
                    />
                  </Grid>

                  <Grid item xs={12} sm='auto'>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      label='Salesperson'
                      onChange={e => {
                        setParameters({ ...parameters, salespersonId: e.target.value ?? undefined })
                      }}
                      select
                      SelectProps={{ displayEmpty: true }}
                      sx={{ minWidth: '200px', maxWidth: '200px' }}
                      value={parameters.salespersonId || ''}
                    >
                      <MenuItem value={''}>All Team Members</MenuItem>
                      {salespeople
                        .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
                        .map(salesperson => (
                          <MenuItem key={salesperson.id} value={salesperson.id}>
                            {salesperson.firstName} {salesperson.lastName}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm='auto'>
                    <ChipStatusSelect
                      label='Statuses'
                      onChange={value => {
                        setParameters({
                          ...parameters,
                          statuses: value
                        })
                      }}
                      options={[
                        {
                          label: `Prospect (${getStatusCount(statusCounts, 'isProspect')})`,
                          color: 'blue',
                          value: 'isProspect'
                        },
                        {
                          label: `Opportunity (${getStatusCount(statusCounts, 'isOpportunity')})`,
                          color: 'green',
                          value: 'isOpportunity'
                        },
                        {
                          label: `Send Proposal (${getStatusCount(statusCounts, 'isSendProposal')})`,
                          color: 'yellow',
                          value: 'isSendProposal'
                        },
                        {
                          label: `Get Approval (${getStatusCount(statusCounts, 'isGetApproval')})`,
                          color: 'purple',
                          value: 'isGetApproval'
                        },
                        {
                          label: `Get Payment (${getStatusCount(statusCounts, 'isGetPayment')})`,
                          color: 'blue',
                          value: 'isGetPayment'
                        },
                        {
                          label: `Sold (${getStatusCount(statusCounts, 'isSold')})`,
                          color: 'green',
                          value: 'isSold'
                        },
                        {
                          label: `Lost (${getStatusCount(statusCounts, 'isLost')})`,
                          color: 'red',
                          value: 'isLost'
                        },
                        {
                          label: `Past Due (${getStatusCount(statusCounts, 'isPastDue')})`,
                          color: 'red',
                          value: 'isPastDue'
                        }
                      ]}
                      value={parameters.statuses ?? []}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm='auto'>
                  <Button
                    endIcon={<Add />}
                    onClick={() => {
                      setOpportunity(new OpportunityDto())
                      setOpportunityAddEditDialogOpen(true)
                    }}
                    variant='text'
                  >
                    ADD OPPORTUNITY
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {opportunities.map(opportunity => (
          <Grid item xs={12}>
            <OpportunityHeroCard opportunity={opportunity}>
              <Grid item xs={12} sm='auto'>
                <Button
                  endIcon={<Add />}
                  onClick={() => {
                    setOpportunity(opportunity)
                    setOpportunityActivityAddDialogOpen(true)
                  }}
                  variant='text'
                >
                  ADD ACTIVITY
                </Button>
              </Grid>

              <Grid item xs={12} sm='auto'>
                <Button
                  endIcon={<AppRegistration />}
                  onClick={() => {
                    navigate(`/opportunities/${opportunity.id}`)
                  }}
                  variant='text'
                >
                  DETAILS
                </Button>
              </Grid>
            </OpportunityHeroCard>
          </Grid>
        ))}

        <Grid item xs={12}>
          <ExplanationAccordion>
            Search searches by Opportunity Name, Opportunity Description, Target Company Name, Target Person First Name + Last
            Name. || Opportunities is only accessible by users with salesperson access.||By default the page loads with all
            sales opportunities for the signed in person that have not been marked sold or lost.|| Cards are sorted by Next
            Activity Date ascending. ||The Statuses multi-select filters the list by the selected Statuses. ||A blue chip will
            display 'Today' if the opportunity's Next Activity Date is today.||A red chip will display 'Past Due' if there is no
            activities for the opportunity or if the Next Activity Date is before today. || A green chip will display
            'Opportunity' if the last activity status is "Opportunity". || A yellow chip will display 'Internal Review' if the
            last activity status is "Internal Review". || A purple chip will display 'Send Quote' if the last activity status is
            "Send Quote". || A blue chip will display 'Quote Approved' if the last activity status is "Quote Approved".||A
            purple chip will display 'Waiting on Approval' if the last activity status is "Waiting on Approval". || A green chip
            will display 'Sold' if the last activity status is "Sold". || A red chip will display 'Lost' if the last activity
            status is "Lost".
          </ExplanationAccordion>
        </Grid>

        <Grid item xs={12}>
          <TablePagination
            component='div'
            count={totalCount}
            onPageChange={(_, page: number) => {
              setParameters({
                ...parameters,
                page
              })
            }}
            onRowsPerPageChange={e => {
              setParameters({
                ...parameters,
                pageSize: e.target.value as unknown as number
              })
            }}
            page={parameters.page}
            rowsPerPage={parameters.pageSize}
            labelRowsPerPage={
              <Grid container spacing={1}>
                <Grid item>Order By:</Grid>

                <Grid item>
                  <TextField
                    InputProps={{ disableUnderline: true, sx: { fontSize: 'inherit', pl: 1 } }}
                    onChange={e => {
                      setParameters({ ...parameters, orderBy: e.target.value })
                    }}
                    select
                    size='small'
                    value={parameters.orderBy}
                    variant='standard'
                  >
                    <MenuItem value='targetCompany'>Target Company</MenuItem>

                    <MenuItem value='targetPerson'>Target Person</MenuItem>

                    <MenuItem value='opportunityDescription'>Description</MenuItem>

                    <MenuItem value='createdDateTime'>Last Activity Date</MenuItem>

                    <MenuItem value='opportunityActivityType'>Last Activity Type</MenuItem>

                    <MenuItem value='activityNote'>Last Activity Note</MenuItem>

                    <MenuItem value='nextActivityDate'>Next Activity Date</MenuItem>

                    <MenuItem value='opportunityNextActivityType'>Next Activity Type</MenuItem>
                  </TextField>
                </Grid>

                <Grid item>
                  <TextField
                    InputProps={{ disableUnderline: true, sx: { fontSize: 'inherit', pl: 1 } }}
                    onChange={e => {
                      setParameters({ ...parameters, order: e.target.value as 'asc' | 'desc' })
                    }}
                    select
                    size='small'
                    value={parameters.order}
                    variant='standard'
                  >
                    <MenuItem value='asc'>Ascending</MenuItem>

                    <MenuItem value='desc'>Descending</MenuItem>
                  </TextField>
                </Grid>

                <Grid item sx={{ alignSelf: 'stretch' }}>
                  <Divider orientation='vertical' />
                </Grid>

                <Grid item>Cards Per Page:</Grid>
              </Grid>
            }
            rowsPerPageOptions={[
              { label: '5', value: 5 },
              { label: '10', value: 10 },
              { label: '25', value: 25 },
              { label: '50', value: 50 },
              { label: '100', value: 100 }
            ]}
            SelectProps={{ fullWidth: false }}
          />
        </Grid>
      </Grid>
    </>
  )
}
